import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Appointment } from '../models/appointment';
import { SchedulesService } from '../services/schedules.service';
import { ClinicInfoService } from '../services/clinicInfo.service';
import { Title } from '@angular/platform-browser';
import { getAnalytics, logEvent } from 'firebase/analytics';

@Component({
  selector: 'app-reagenda',
  templateUrl: './reagenda.component.html',
  styleUrls: ['./reagenda.component.scss'],
})
export class ReagendaComponent {
  token: string = '';
  showPast: boolean = false;
  appointments: Array<Appointment> = [];
  loading: boolean = true;
  months: Array<string> = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];
  cancelling: boolean = false;
  validAppointments: number = 0;
  analytics = getAnalytics();
  constructor(
    private route: ActivatedRoute,
    private schedules: SchedulesService,
    private cookieService: CookieService,
    public clinicInfoService: ClinicInfoService,
    private router: Router,
    private titleService: Title
  ) {
    logEvent(this.analytics, 'reagenda_loaded');
    this.titleService.setTitle('HolaGlow - Reagenda');
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.token = params['token'];
      this.showPast = params['showPast'] == 'true';
      this.validAppointments = 0;
      this.schedules.next(this.token).subscribe({
        next: (x) => {
          this.appointments = x;
          this.loading = false;
          x.forEach((y) => {
            if (!y.isPast || (y.isPast && this.showPast)) {
              this.validAppointments++;
            }
            y.startTimeDate = new Date(y.startTime);
          });
        },
        error: (e) => {
          this.loading = false;
        },
      });
    });
  }

  cancelVisit(appointment: Appointment) {
    logEvent(this.analytics, 'cancel_visit');
    if (!this.cancelling) {
      this.cancelling = true;

      this.schedules.cancel(appointment).subscribe(() => {
        const index = this.appointments.indexOf(appointment, 0);
        if (index > -1) {
          var startTime = new Date(appointment.startTime);
          var url =
            'https://www.holaglow.com/cita-cancelada?day=' +
            startTime.getDate() +
            '&month=' +
            this.months[startTime.getMonth()] +
            '&hour=' +
            startTime.getHours().toString().padStart(2, '0') +
            ':' +
            startTime.getMinutes().toString().padStart(2, '0');
          try {
            if (
              window.parent.location.href.indexOf('localhost') > 0 ||
              window.parent.location.href.indexOf('.web.app') > 0
            ) {
              window.parent.location.href = url;
            } else {
              location.href = url;
            }
          } catch (e) {
            location.href = url;
          }
        }
      });
    }
  }

  reagenda(appointment: Appointment) {
    logEvent(this.analytics, 'reagenda');
    this.cookieService.deleteAll();
    this.cookieService.set('appointmentToCancel', JSON.stringify(appointment), {
      expires: 30,
      sameSite: 'None',
      secure: true,
    });
    this.cookieService.set('clinicToken', this.token, {
      expires: 30,
      sameSite: 'None',
      secure: true,
    });
    this.cookieService.set('treatment', appointment.treatment, {
      expires: 30,
      sameSite: 'None',
      secure: true,
    });
    this.cookieService.set('box', appointment.box, {
      expires: 30,
      sameSite: 'None',
      secure: true,
    });
    var url = 'https://holaglow.com/agenda';
    try {
      if (
        window.parent.location.href.indexOf('localhost') > 0 ||
        window.parent.location.href.indexOf('.web.app') > 0
      ) {
        this.router.navigate(['/schedule']);
      } else {
        location.href = url;
      }
    } catch (e) {
      location.href = url;
    }
  }

  shouldShowCancel(appointment: Appointment) {
    var date = new Date(Date.parse(appointment.startTime.replace(' ', 'T')));
    var now = new Date();
    var diffInTime = date.getTime() - now.getTime();
    var diffInHours = diffInTime / (1000 * 3600);
    return diffInHours > 24;
  }
}
