import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-financing',
  templateUrl: './financing.component.html',
  styleUrls: ['./financing.component.scss'],
})
export class FinancingComponent {
  name: string | null = '';
  constructor(private router: Router, private cookieService: CookieService) {
    this.name = this.cookieService.get('name');
  }
  openAlma() {
    open('https://shop.getalma.eu/');
  }

  openPepper() {
    open('https://www.pepperspain.com/pepper/Page.aspx?__IDAPPLGN=3470');
  }

  open(url: string) {
    window.open(url, '_blank');
  }

  back() {
    this.router.navigate(['/dashboard']);
  }
}
