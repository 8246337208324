import { DatePipe, Time } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  NgbDate,
  NgbDatepicker,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { Appointment } from '../models/appointment';
import { ClinicAvailability } from '../models/clinicavailability';
import { RescheduleAppointmentRequest } from '../models/requests/rescheduleappointmentrequest';
import { Treatment, TreatmentInfo } from '../models/treatments';
import { SchedulesService } from '../services/schedules.service';
import { Slot } from '../models/slot';
import { DayAvailability } from '../models/dayavailability';
import { RedirectService } from '../services/redirect.service';
import { ClinicInfoService } from '../services/clinicInfo.service';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { Title } from '@angular/platform-browser';
import { ContactsService } from '../services/contacts.service';

@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.scss'],
})
export class SchedulerComponent {
  date: NgbDateStruct;
  hours: Map<NgbDate, Array<Time>> = new Map();
  morningHoursList: Array<Time> = [];
  afternoonHoursList: Array<Time> = [];
  availability: Array<ClinicAvailability> = [];
  selectedTime: Time = {
    hours: 0,
    minutes: 0,
  };
  slots: Array<Slot> = [];
  idPvOnline: string = '5364';
  selectedDate: NgbDate | undefined;
  error: boolean = false;
  months: Array<string> = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];
  @ViewChild(NgbDatepicker, { static: true }) dp: NgbDatepicker | undefined;
  loading: boolean = false;
  treatments: Array<Treatment> = [];
  possibleTreatments: Array<TreatmentInfo> = [];
  familySelected: string = 'Familia';
  format: string = 'yyyy-MM-dd HH:mm:ss';
  dashboard: boolean = false;
  treatmentText: string = '';
  closedDays: Array<string> = [];
  treatmentSelected: number = 0;
  loadingHours: boolean = false;
  month: number = 0;
  year: number = 0;
  initialMonth: number = 0;
  initialYear: number = 0;
  initialDay: number = 0;
  maxMonth: number = 0;
  maxYear: number = 0;
  maxDay: number = 0;
  referral: string = '';
  doubleAppointment: boolean = false;
  availableDates: Array<DayAvailability> = [];
  showClinicSelector: boolean = true;
  treatmentData: string = '';
  clinicId: string = '';
  address: string = '';
  parkingAddress: string = '';
  selectedDateAsDate: Date = new Date();
  analytics = getAnalytics();
  shouldClose = false;
  constructor(
    private scheduleService: SchedulesService,
    public datepipe: DatePipe,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private redirectService: RedirectService,
    private clinicInfoService: ClinicInfoService,
    private titleService: Title,
    private service: ContactsService
  ) {
    
    var requestSaveContact = this.cookieService.get('requestSaveContact');
    if(requestSaveContact){
      var request = JSON.parse(requestSaveContact);
      this.service.save(request).subscribe(
        (x) => {
          this.cookieService.set('clinicToken', x.clinicToken, {
            expires: 30,
            sameSite: 'None',
            secure: true,
          });
          this.cookieService.delete('requestSaveContact');
      });
    }
    this.titleService.setTitle('HolaGlow - Agenda');
    var now = new Date();
    this.month = now.getMonth();
    this.year = now.getFullYear();
    this.date = {
      year: now.getFullYear(),
      month: now.getMonth(),
      day: now.getDate(),
    };
    this.initialMonth = now.getMonth();
    this.initialYear = now.getFullYear();
    this.initialDay = now.getDate();

    this.availability = [
      {
        day: 0,
        closed: false,
        endTime: {
          hours: 20,
          minutes: 30,
        },
        startTime: {
          hours: 10,
          minutes: 30,
        },
      },
      {
        day: 1,
        closed: false,
        endTime: {
          hours: 20,
          minutes: 30,
        },
        startTime: {
          hours: 10,
          minutes: 30,
        },
      },
      {
        day: 2,
        closed: false,
        endTime: {
          hours: 20,
          minutes: 30,
        },
        startTime: {
          hours: 10,
          minutes: 30,
        },
      },
      {
        day: 3,
        closed: false,
        endTime: {
          hours: 20,
          minutes: 30,
        },
        startTime: {
          hours: 10,
          minutes: 30,
        },
      },
      {
        day: 4,
        closed: false,
        endTime: {
          hours: 20,
          minutes: 30,
        },
        startTime: {
          hours: 10,
          minutes: 30,
        },
      },
      {
        day: 5,
        closed: false,
        endTime: {
          hours: 14,
          minutes: 0,
        },
        startTime: {
          hours: 10,
          minutes: 0,
        },
      },
      {
        day: 6,
        closed: true,
        endTime: null,
        startTime: null,
      },
    ];

    this.route.queryParams.subscribe((params) => {
      var token = params['token'];
      if (token) {
        this.cookieService.set('clinicToken', token, {
          expires: 30,
          sameSite: 'None',
          secure: true,
        });
        this.shouldClose = true;
        this.cookieService.set('externalReference', '14', {
          expires: 30,
          sameSite: 'None',
          secure: true,
        });
      }
      this.dashboard = params['mode'] == 'dashboard';
      this.doubleAppointment = params['doubleAppointment'] == 'true';
      this.referral = this.cookieService.get('referral') ?? '';
      if (this.dashboard) {
        this.cookieService.set('box', '6', {
          expires: 30,
          sameSite: 'None',
          secure: true,
        });
      }
      var box = params['box'];
      var page = params['page'];

      if (box && page) {
        var previousTreatment = this.cookieService.get('treatment');
        if (previousTreatment && !this.cookieService.get('previousTreatment')) {
          this.cookieService.set('previousTreatment', previousTreatment, {
            expires: 30,
            sameSite: 'None',
            secure: true,
          });
        }
        this.cookieService.set('treatment', page, {
          expires: 30,
          sameSite: 'None',
          secure: true,
        });
        this.cookieService.set('box', box, {
          expires: 30,
          sameSite: 'None',
          secure: true,
        });
      }

      this.treatmentText = params['treatmentText'] ?? '';
      var treatmentText = this.cookieService.get('treatmentText');
      if (treatmentText) {
        this.treatmentText = treatmentText;
      }

      this.treatments = environment.treatments;
      this.closedDays = environment.closedDays;

      var treatmentId = this.cookieService.get('treatment');
      if (!treatmentId || treatmentId == '') {
        treatmentId = '902';
        this.cookieService.set('treatment', treatmentId.toString(), {
          expires: 30,
          sameSite: 'None',
          secure: true,
        });
      }
      this.treatments.forEach((x) => {
        x.treatments.forEach((y) => {
          if (y.id.toString() == treatmentId) {
            this.showClinicSelector = y.availableBcn;
            this.treatmentText = y.text;
          }
        });
      });
      if(treatmentId == this.idPvOnline){
        this.clinicChange('5');
      }
    });
  /*  var utmCampaign = this.cookieService.get('utm_campaign');
    if (utmCampaign.toLowerCase().indexOf('bcn') > -1) {
      this.clinicChange('4');
    }
*/
    var treatmentId = this.cookieService.get('treatment');
    if (!treatmentId || treatmentId == '') {
      treatmentId = '902';
      this.cookieService.set('treatment', treatmentId.toString(), {
        expires: 30,
        sameSite: 'None',
        secure: true,
      });
    }
    if(treatmentId == this.idPvOnline){
      this.clinicChange('5');
    }
  }

  ngOnInit() {
    logEvent(this.analytics, 'scheduler_loaded');
    var today = new Date();
  }

  loadMonth(year: number, month: number, loopCount: number = 0) {
    logEvent(this.analytics, 'load_month');
    var urlParams = new URLSearchParams(window.location.search);
    var customDate = urlParams.get('customDate');
    var isNextMonth = this.availableDates.length > 0;
    var service = this.cookieService.get('treatment');
    var maxDays = 10;
    if(this.clinicId == "1" || this.clinicId == "5"){
      maxDays = 10;
    }
    if(service == this.idPvOnline){
      maxDays = 7;
    }
    var now = new Date();
    now.setDate(now.getDate() + maxDays);
    this.maxMonth = now.getMonth();
    this.maxYear = now.getFullYear();
    this.maxDay = now.getDate();
    var today = customDate?.length ? new Date(customDate) : new Date();
    today.setHours(0, 0, 0, 0);

    if (
      (!this.loadingHours && this.availableDates.length < maxDays) ||
      (service != '902' && service != '903')
    ) {
      this.loadingHours = true;
      var dateToCheck = new Date(year, month, 5);

      var previousTreatment = this.cookieService.get('appointmentToCancel');
      if(previousTreatment){
        this.scheduleService
        .monthAvailability(dateToCheck, service, this.clinicId)
        .subscribe(
          (x) => {
            logEvent(this.analytics, 'load_month_finished');
            x.dayAvailabilities.forEach((y) => {
              var date = new Date(y.date);
              if (
                (this.availableDates.length < maxDays &&
                  date >= today && y.availability) ||
                (service != '902' && service != '903')
              ) {
                this.availableDates.push(y);
              }
            });

            this.loadingHours = false;
            this.date = { year: 1789, month: 7, day: 14 };/*
            if (this.availableDates.length < maxDays && loopCount < 3) {
              const nextMonth = (month + 1) % 12;
              const nextYear = nextMonth === 0 ? year + 1 : year;
              this.loadMonth(nextYear, nextMonth, loopCount + 1);
            }*/
          },
          (error) => {
            this.loadingHours = false;
          }
        );
      }
      else{
        this.scheduleService
        .monthAvailabilityv2(dateToCheck, service, this.clinicId)
        .subscribe(
          (x) => {
            logEvent(this.analytics, 'load_month_finished');
            x.dayAvailabilities.forEach((y) => {
              var date = new Date(y.date);
              console.log(y);
              if (
                (this.availableDates.length < maxDays &&
                  (!isNextMonth ? date >= today : true) && y.availability) ||
                (service != '902' && service != '903')
              ) {
                this.availableDates.push(y);
              }
            });

            this.loadingHours = false;
            this.date = { year: 1789, month: 7, day: 14 };
            if (this.availableDates.length < maxDays && loopCount < 3) {
              const nextMonth = (month + 1) % 12;
              const nextYear = nextMonth === 0 ? year + 1 : year;
              this.loadMonth(nextYear, nextMonth, loopCount + 1);
            }
          },
          (error) => {
            this.loadingHours = false;
          }
        );
      }
    }
  }

  loadData(date: Date) {
    logEvent(this.analytics, 'load_date');
    this.loadingHours = true;
    var box = this.cookieService.get('box');
    var treatment = this.cookieService.get('treatment');
    if (!box || box === 'undefined') {
      var id = this.cookieService.get('treatment');
      this.treatments.forEach((x) => {
        x.treatments.forEach((y) => {
          if (y.id.toString() == id) {
            box = y.box.toString();
          }
        });
      });
      this.cookieService.set('box', box, {
        expires: 30,
        sameSite: 'None',
        secure: true,
      });
    }
    if (this.doubleAppointment) {
      this.scheduleService
        .slots(date, treatment, this.clinicId)
        .subscribe((x) => {
          this.scheduleService
            .slots(date, '902', this.clinicId)
            .subscribe((y) => {
              this.slots = y;
              var dateIteration = new Date(date);
              this.fillHoursPerDate(
                new NgbDate(
                  dateIteration.getFullYear(),
                  dateIteration.getMonth() + 1,
                  dateIteration.getDate()
                ),
                x
              );
              this.loadingHours = false;
            });
        });
    } else {
      var previousTreatment = this.cookieService.get('appointmentToCancel');
      if(previousTreatment){
        this.scheduleService
        .slots(date, treatment, this.clinicId)
        .subscribe((x) => {
          logEvent(this.analytics, 'load_date_finished');
          this.slots = x;
          var dateIteration = new Date(date);
          this.fillHoursPerDate(
            new NgbDate(
              dateIteration.getFullYear(),
              dateIteration.getMonth() + 1,
              dateIteration.getDate()
            )
          );
          this.loadingHours = false;
        });
      }
      else{
        this.scheduleService
        .slotsv2(date, treatment, this.clinicId)
        .subscribe((x) => {
          logEvent(this.analytics, 'load_date_finished');
          this.slots = x;
          var dateIteration = new Date(date);
          this.fillHoursPerDate(
            new NgbDate(
              dateIteration.getFullYear(),
              dateIteration.getMonth() + 1,
              dateIteration.getDate()
            )
          );
          this.loadingHours = false;
        });
      }
    }
  }
  ngAfterViewInit() {}

  fillHoursPerDate(date: NgbDate, secondSlots?: Array<Slot>) {
    this.error = false;
    this.selectedDate = date;
    this.selectedTime = {
      hours: 0,
      minutes: 0,
    };
    var dateToCheck = new Date(date.year, date.month - 1, date.day);
    var hours: Array<Time> = [];
    this.slots.forEach((slot) => {
      var hour = slot.startTime.split(':')[0];
      var minutes = slot.startTime.split(':')[1];
      if (
        (minutes == '00' || minutes == '30') &&
        !(hour == '10' && minutes == '00')
      ) {
        if (this.doubleAppointment && secondSlots) {
          var secondDate = new Date(
            date.year,
            date.month - 1,
            date.day,
            Number.parseInt(hour),
            Number.parseInt(minutes)
          );
          secondDate.setMinutes(secondDate.getMinutes() + 15);
          var timeToSearch =
            secondDate.getHours().toString().padStart(2, '0') +
            ':' +
            secondDate.getMinutes().toString().padStart(2, '0');
          var existsSecondSlot = secondSlots.findIndex(
            (x) => x.startTime == timeToSearch
          );
          if (existsSecondSlot >= 0) {
            hours.push({
              hours: Number.parseInt(hour),
              minutes: Number.parseInt(minutes),
            });
          }
        } else {
          hours.push({
            hours: Number.parseInt(hour),
            minutes: Number.parseInt(minutes),
          });
        }
      }
    });
    this.hours.set(
      new NgbDate(
        dateToCheck.getFullYear(),
        dateToCheck.getMonth() + 1,
        dateToCheck.getDate()
      ),
      hours
    );

    const morningHours = hours.filter((hour) => hour.hours < 16);
    const afternoonHours = hours.filter((hour) => hour.hours >= 16);
    this.morningHoursList = morningHours;
    this.afternoonHoursList = afternoonHours;
    window.parent.postMessage('(scroll)', 'https://www.holaglow.com');
  }

  scrollToElement(elementId: string, threshold = 0): void {
    const element = document.getElementById(elementId);
    if (element) {
      setTimeout(() => {
        window.scrollTo({
          top: element.offsetTop - threshold,
          left: 0,
          behavior: 'smooth',
        });
      }, 1000);
    }
  }
  onDateSelect(date: NgbDate) {
    this.morningHoursList = [];
    this.afternoonHoursList = [];
    this.error = false;
    this.selectedDate = date;
    this.selectedDateAsDate = new Date(date.year, date.month - 1, date.day);
    this.selectedTime = {
      hours: 0,
      minutes: 0,
    };
    this.loadData(new Date(date.year, date.month - 1, date.day));
  }

  markDisabled = (
    date: NgbDate,
    current?: { year: number; month: number }
  ): boolean => {
    var dateToCheck = new Date(date.year, date.month - 1, date.day, 21, 0);
    if (dateToCheck < new Date()) return true;
    var dateString =
      date.year +
      '-' +
      date.month.toString().padStart(2, '0') +
      '-' +
      date.day.toString().padStart(2, '0');
    var closed = this.closedDays.find((x) => x == dateString) != undefined;
    var availability =
      this.availableDates.find((x) => x.date == dateString)?.availability ??
      false;
    var markDisabled =
      dateToCheck.getDay() === 0 ||
      dateToCheck < new Date() ||
      closed ||
      !availability;
    return markDisabled;
  };

  hourSelected(selected: Time) {
    this.error = false;
    this.selectedTime = selected;
  }
  confirmVisit() {
    logEvent(this.analytics, 'confirm_visit');
    if (
      this.date != undefined &&
      this.selectedTime.hours > 0 &&
      !this.loading
    ) {
      this.loading = true;
      var startTime = new Date(
        this.date.year,
        this.date.month - 1,
        this.date.day,
        this.selectedTime.hours,
        this.selectedTime.minutes
      );
      var endTime = new Date(
        this.date.year,
        this.date.month - 1,
        this.date.day,
        this.selectedTime.hours,
        this.selectedTime.minutes
      );
      endTime.setMinutes(endTime.getMinutes() + 30);
      var previousTreatment = this.cookieService.get('previousTreatment');
      var comment = '';
      if (
        this.treatmentText &&
        this.treatmentText != '' &&
        this.treatmentText != 'undefined'
      ) {
        comment = 'Tratamiento visto en web: ' + this.treatmentText;
      } else if (previousTreatment) {
        this.treatments.forEach((x) => {
          x.treatments.forEach((y) => {
            if (y.id == Number(previousTreatment!)) {
              comment = 'Tratamiento visto en web: ' + y.text;
            }
          });
        });
      }
      var treatmentText = '';
      var treatment = this.cookieService.get('treatment') ?? '';

      var box = '';
      this.slots.forEach((x) => {
        if (
          x.startTime ==
          this.selectedTime.hours.toString().padStart(2, '0') +
            ':' +
            this.selectedTime.minutes.toString().padStart(2, '0')
        ) {
          box = x.box;
        }
      });
      if (this.treatmentSelected > 0) {
        treatment = this.treatmentSelected.toString();
      }
      this.treatments.forEach((x) => {
        x.treatments.forEach((y) => {
          if (y.id.toString() == treatment) {
            treatmentText = y.text;
          }
        });
      });
      var clientId = this.cookieService.get('clinicToken') ?? '';
      var appointment: Appointment = {
        box: box,
        endTime: this.datepipe.transform(endTime, this.format)!,
        id: '0',
        startTime: this.datepipe.transform(startTime, this.format)!,
        treatment: treatment,
        clientId: clientId,
        comment: comment,
        treatmentText: treatmentText,
        referralId: this.referral ?? '',
        externalReference: this.cookieService.get('externalReference') ?? '',
        isPast: false,
        clinicId: this.clinicId,
        isCancelled: false,
        startTimeDate: undefined
      };
      var appointments = [appointment];
      if (this.doubleAppointment) {
        appointment.box = '5';
        appointment.treatment = '902';
        var startTime2 = new Date(
          this.date.year,
          this.date.month - 1,
          this.date.day,
          this.selectedTime.hours,
          this.selectedTime.minutes
        );
        var endTime2 = new Date(
          this.date.year,
          this.date.month - 1,
          this.date.day,
          this.selectedTime.hours,
          this.selectedTime.minutes
        );
        startTime2.setMinutes(startTime2.getMinutes() + 15);
        endTime2.setMinutes(endTime2.getMinutes() + 45);
        var appointment2: Appointment = {
          box: box,
          endTime: this.datepipe.transform(endTime2, this.format)!,
          id: '0',
          startTime: this.datepipe.transform(startTime2, this.format)!,
          treatment: treatment,
          clientId: clientId,
          comment: comment,
          treatmentText: treatmentText,
          referralId: this.referral ?? '',
          externalReference: this.cookieService.get('externalReference') ?? '',
          isPast: false,
          clinicId: this.clinicId,
          isCancelled: false,
          startTimeDate: undefined
        };
        appointments = [appointment, appointment2];
      }
      var appointmentToCancel = this.cookieService.get('appointmentToCancel');
      if (appointmentToCancel != undefined && appointmentToCancel != '') {
        var request: RescheduleAppointmentRequest = {
          next: appointment,
          previous: JSON.parse(appointmentToCancel),
        };
        this.scheduleService.reschedule(request).subscribe((x) => {
          this.redirectService.redirectToThankYou(
            startTime,
            this.dashboard,
            this.clinicId
          );
        });
      } else {
        if (!clientId || clientId == '') {
          this.cookieService.set('clinicId', this.clinicId, {
            expires: 30,
            sameSite: 'None',
            secure: true,
          });
          this.cookieService.set(
            'appointmentsToSend',
            JSON.stringify(appointments),
            {
              expires: 30,
              sameSite: 'None',
              secure: true,
            }
          );
          this.redirectService.redirectToContactForm();
        } else {
          this.scheduleService.scheduleBulk(appointments).subscribe((x) => {
            logEvent(this.analytics, 'save_appointment_finished');
            if (this.shouldClose) window.close();
            this.redirectService.redirectToThankYou(
              startTime,
              this.dashboard,
              this.clinicId
            );
          });
        }
      }
    } else {
      this.error = true;
    }
  }

  onFamilySelected(event: any) {
    this.treatments.forEach((x) => {
      if (x.family == this.familySelected) {
        this.possibleTreatments = x.treatments;
      }
    });
    this.treatmentSelected = this.possibleTreatments[0].id;
    this.onTreatmentSelected(null);
  }

  onTreatmentSelected(event: any) {
    var previousBox = this.cookieService.get('box');
    var previousTreatment = this.cookieService.get('treatment');
    this.cookieService.set('treatment', this.treatmentSelected.toString(), {
      expires: 30,
      sameSite: 'None',
      secure: true,
    });
    if (this.treatmentSelected == 901) {
      this.cookieService.set('box', '5', {
        expires: 30,
        sameSite: 'None',
        secure: true,
      });
    } else if (this.treatmentSelected == 902) {
      this.cookieService.set('box', '5', {
        expires: 30,
        sameSite: 'None',
        secure: true,
      });
    } else {
      this.cookieService.set('box', '6', {
        expires: 30,
        sameSite: 'None',
        secure: true,
      });
    }
    var newBox = this.cookieService.get('box');
    if (
      (previousBox != newBox ||
        previousTreatment != this.treatmentSelected.toString()) &&
      this.clinicId != ''
    ) {
      this.availableDates = [];
      this.loadMonth(this.year, this.month - 1, 0);
      if (this.selectedDate != undefined) {
        this.loadData(
          new Date(
            this.selectedDate!.year,
            this.selectedDate!.month - 1,
            this.selectedDate?.day
          )
        );
      }
    }
  }

  navigation(event: any) {
    this.month = event.next.month;
    this.year = event.next.year;
    if (this.address.length > 0) {
      this.loadMonth(this.year, this.month - 1, 0);
    }
  }

  clinicChange(clinicId: string) {
    if (this.clinicId != clinicId) {
      this.clinicId = clinicId;
      this.address = this.clinicInfoService.getClinicAddress(clinicId);
      this.parkingAddress = this.clinicInfoService.getClinicParkingAddress(clinicId);
      this.availableDates = [];
      this.selectedDate = undefined;
      var year = new Date().getFullYear();
      var month = new Date().getMonth() + 1;
      if (this.month != month || this.year != year) {
        this.dp?.navigateTo({
          year: year,
          month: month,
        });
      } else {
        this.loadMonth(this.year, this.month - 1, 0);
      }
    }
  }
}
