<div
  class="container justify-content-md-center container sch-wrapper"
  [ngClass]="{ 'dashboard background': dashboard }"
>
  <div class="row treatments" *ngIf="dashboard">
    <div class="col-6 justify-content-md-center container text-center">
      <h3>Familia:</h3>
      <select
        class="form-select"
        aria-label="Familia"
        [(ngModel)]="familySelected"
        (change)="onFamilySelected($event)"
      >
        <option
          *ngFor="let treatment of treatments"
          value="{{ treatment.family }}"
        >
          {{ treatment.family }}
        </option>
      </select>
      <h3>Tratamiento:</h3>
      <select
        class="form-select"
        aria-label="Tratamiento"
        (change)="onTreatmentSelected($event)"
        [(ngModel)]="treatmentSelected"
        [disabled]="familySelected == 'Familia'"
      >
        <option
          *ngFor="let treatment of possibleTreatments"
          value="{{ treatment.id }}"
        >
          {{ treatment.text }}
        </option>
      </select>
    </div>
  </div>

  <div class="media-wrapper" [ngClass]="{ dashboard: dashboard == true }">
    <div>
      <div class="row">
        <h3>Selecciona clínica y fecha para tu cita</h3>
      </div>
      <div class="row" *ngIf="treatmentText != 'Probador Virtual Online'">
        <p class="only-madrid" *ngIf="!showClinicSelector">
          El tratamiento <b>{{ treatmentText }}</b> sólo esta disponible en
          nuestra clínica de Madrid.
        </p>
        <div class="clinic-buttons">
          <button
            [ngClass]="{ isSelected: clinicId === '1' }"
            class="btn btn-hg-white hourButton"
            type="submit"
            id="button-addon2"
            (click)="clinicChange('1')"
          >
            Madrid
          </button>
          <button
            [ngClass]="{
              isSelected: clinicId === '4',
              isDisabled: !showClinicSelector
            }"
            class="btn btn-hg-white hourButton"
            type="submit"
            id="button-addon2"
            (click)="clinicChange('4')"
          >
            Barcelona
          </button>
          <button
            [ngClass]="{
              isSelected: clinicId === '5',
              isDisabled: !showClinicSelector
            }"
            class="btn btn-hg-white hourButton"
            type="submit"
            id="button-addon2"
            (click)="clinicChange('5')"
          >
            Valencia
          </button>
        </div>
      </div>

      <div class="row">
        <div class="clinic-address" *ngIf="address.length > 0">
          <img class="map-pin" src="./../../assets/icon-map-pin.png" />
          {{ address }}
        </div>
        <div class="clinic-address" *ngIf="parkingAddress.length > 0">
          <img class="map-pin" src="./../../assets/icon-parking-pin.svg" />
          {{parkingAddress}}
        </div>
      </div>

      <div
        id="datepicker"
        class="datepicker-wrapper"
        [ngClass]="{
          isLoading: loadingHours && address.length > 0,
          isDisabled: address.length === 0
        }"
      >
        <div class="datepicker-loading-wrapper">
          <span
            *ngIf="loadingHours && address.length > 0"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        </div>
        <ngb-datepicker
          #dp
          [disabled]="loadingHours"
          [markDisabled]="markDisabled"
          [minDate]="{
            year: initialYear,
            month: initialMonth + 1,
            day: initialDay
          }"
          [maxDate]="{
            year: maxYear,
            month: maxMonth+1,
            day: maxDay
          }"
          (navigate)="navigation($event)"
          (dateSelect)="onDateSelect($event)"
          [(ngModel)]="date"
        ></ngb-datepicker>
      </div>
    </div>

    <div id="hour-list">
      <div
        class="row select-hour"
        *ngIf="
          (morningHoursList.length > 0 || afternoonHoursList.length > 0) &&
          date &&
          selectedDate
        "
      >
        <label
          >Selecciona hora para el
          <b
            >{{ selectedDateAsDate | date : "EEEE" }},
            {{ selectedDateAsDate | date : "d" }} de
            {{ selectedDateAsDate | date : "MMMM" }}</b
          >
        </label>
        <div class="hours-wrapper" *ngIf="morningHoursList.length > 0">
          <p>Horario de mañana</p>
          <div class="hours-list">
            <button
              *ngFor="let hour of morningHoursList"
              class="btn btn-hg-white hourButton"
              [ngClass]="{ selected: this.selectedTime == hour }"
              id="button-addon2"
              (click)="hourSelected(hour)"
            >
              {{ hour.hours.toString().padStart(2, "0") }}:{{
                hour.minutes.toString().padStart(2, "0")
              }}
            </button>
          </div>
        </div>
        <div class="hours-wrapper" *ngIf="afternoonHoursList.length > 0">
          <p>Horario de tarde</p>
          <div class="hours-list">
            <button
              *ngFor="let hour of afternoonHoursList"
              class="btn btn-hg-white hourButton"
              [ngClass]="{ selected: this.selectedTime == hour }"
              id="button-addon2"
              (click)="hourSelected(hour)"
            >
              {{ hour.hours.toString().padStart(2, "0") }}:{{
                hour.minutes.toString().padStart(2, "0")
              }}
            </button>
          </div>
        </div>
      </div>
      <div
        class="row marginTop maxWidth no-appointments"
        *ngIf="
          selectedDate &&
          morningHoursList.length == 0 &&
          afternoonHoursList.length == 0 &&
          !loading &&
          !loadingHours
        "
      >
        <img src="./../../assets/sadIcon.svg" alt="sad" class="sad-icon" />
        <h3>¡Lo sentimos!</h3>
        <p>No hay citas para el día seleccionado.</p>
        <span>Haz clic en otro día para ver la disponibilidad</span>
      </div>

      <div
        *ngIf="
          selectedDate &&
          (morningHoursList.length > 0 || afternoonHoursList.length > 0)
        "
        class="row marginTop"
      >
        <button
          class="btn btn-hg-primary confirm-visit"
          [disabled]="selectedTime.hours == 0 || loading"
          id="button-addon2"
          (click)="confirmVisit()"
        >
          <div *ngIf="!loading && !loadingHours" style="margin-bottom: 0px">
            Confirmar visita
          </div>
          <span
            *ngIf="loading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        </button>
      </div>
      <div *ngIf="error" class="align-items-center errorMessage">
        <div>Por favor, selecciona día y hora para la visita</div>
      </div>
    </div>
  </div>
</div>
