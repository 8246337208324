<div class="container dashboard justify-content-md-center text-center background">
    <div class="row">
        <div>
            <div *ngIf="name" class="align-items-center">
                <h1>¡Hola {{name}}! ¿Qué quieres hacer?</h1>
            </div>
            <div class="row">
                <div class="col-5"></div>
                <div class="col-2">
                    <img src="./../../assets/dashboard_icons/agenda.png" (click)="openHolaGlowAgenda()">
                    <label>Agendar cita</label>
                </div>
                <!--<div class="col-2">
                    <img src="./../../assets/dashboard_icons/confirm_appoitment.png" (click)="openFlowww()">
                    <label>Validar cita</label>
                </div>
                <div class="col-2">
                    <img src="./../../assets/dashboard_icons/gdpr.png" (click)="openFlowwwApp()">
                    <label>Firmar GDPR</label>
                </div>-->
            </div>
            <div *ngIf="notFound2" class="container justify-content-md-center container text-center">
                <div  class="align-items-center">
                    <h1>Este paciente no tiene ninguna visita hoy.</h1>
                </div>
            </div>
        </div>
    </div>

    <div class="container justify-content-md-center container text-center">
        <div *ngIf="appointment" class="align-items-center">
            <div>
                <label>Cita paciente encontrada: </label>
                <br/>
                <label>De {{appointment.startTime | date:'medium'}} a {{appointment.endTime | date:'medium'}}</label>
                <br/>
                <label>Tratamiento {{appointment.treatment}}</label>
                <button class="btn btn-hg-primary fullWidth primaryButton"
                (click)="confirm()" id="button-addon2" *ngIf="!confirmed">
                    <label *ngIf="!loading2" style="margin-bottom: 0px;">Confirmar visita</label>
                    <span  *ngIf="loading2" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </button>
                <br/>
                <label *ngIf="confirmed">Cita confirmada con éxito!</label>
            </div>
        </div>
    </div>
    <div class="container justify-content-md-center container text-center logo">
      <img class="fullWidth" src="../../assets/holaglowLogo.svg" height="75px" />
    </div>
    <div class="floating">
        <img src="../../assets/dashboard_icons/logout.png" (click)="logout()" />
    </div>
</div>
