import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ContactformComponent } from './contactform/contactform.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from '@danielmoncada/angular-datetime-picker';
import { SchedulerComponent } from './scheduler/scheduler.component';
import { MatButtonModule } from '@angular/material/button';
import {
  NgbDatepicker,
  NgbDatepickerModule,
  NgbModule,
} from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { DatePipe, JsonPipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import es from '@angular/common/locales/es';

import { registerLocaleData } from '@angular/common';
import { AnalyticsComponent } from './analytics/analytics.component';
import { CheckinComponent } from './checkin/checkin.component';
import { CookieService } from 'ngx-cookie-service';
import { ReagendaComponent } from './reagenda/reagenda.component';

import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { ErrorHandler } from '@angular/core';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { FinancingComponent } from './financing/financing.component';

Bugsnag.start({
  apiKey: 'ddc16c7fe2c290310470f8ce76dfa563',
  appVersion: '1.0.1',
});
registerLocaleData(es);

export function errorHandlerFactory() {
  return new BugsnagErrorHandler();
}
@NgModule({
  declarations: [
    AppComponent,
    ContactformComponent,
    SchedulerComponent,
    DashboardComponent,
    AnalyticsComponent,
    CheckinComponent,
    ReagendaComponent,
    FinancingComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatButtonModule,
    NgbModule,
    NgbDatepicker,
    HttpClientModule,
    AppRoutingModule,
    NgbDatepickerModule,
    FormsModule,
    JsonPipe,
    NgxIntlTelInputModule,
  ],
  providers: [
    DatePipe,
    { provide: LOCALE_ID, useValue: 'es-ES' },
    CookieService,
    { provide: ErrorHandler, useFactory: errorHandlerFactory },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
