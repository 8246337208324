<div
  class="container schedulerContainer justify-content-md-center container text-center dashboard background"
>
  <div
    class="schedulerContainer row justify-content-md-center container text-center"
    style="color: black; padding-top: 50px"
  >
    <div *ngIf="name" class="align-items-center">
      <h1>Financiación</h1>
    </div>
    <div style="width: 75%; margin: 0 auto">
      <div class="row">
        <div class="col-6">
          <img
            src="./../../assets/dashboard_icons/alma.png"
            (click)="openAlma()"
          />
          <label style="font-size: 26px">Alma</label>
        </div>
        <div class="col-6">
          <img
            src="./../../assets/dashboard_icons/pepper.png"
            (click)="openPepper()"
          />
          <label style="font-size: 26px">Pepper</label>
        </div>
      </div>
    </div>
  </div>
  <div class="floating">
    <img src="../../assets/dashboard_icons/back.png" (click)="back()" />
  </div>
</div>
