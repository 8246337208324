import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ClinicInfoService {
  constructor() {}

  getClinicAddress(clinicId: string) {
    const CLINICS = [
      {
        id: '1',
        address: 'C. de Andrés Mellado 3 (frente a Corte Inglés Princesa)',
      },
      {
        id: '4',
        address: 'Avenida Diagonal 299',
      },
      {
        id: '5',
        address: 'Plaza de Alfonso el Magnánimo 6 (metro Colón)',
      },
    ];
    var clinic = CLINICS.filter((clinic) => clinic.id == clinicId);
    var address = "";
    if(clinic) address = clinic[0].address;
    return address;
  }
  getClinicParkingAddress(clinicId: string) {
    const CLINICS = [
      {
        id: '1',
        parkingAddress: 'Parking gratis en C. de Andrés Mellado, 5'
      },
      {
        id: '4',
        parkingAddress: 'Parking gratis en C. de València, 375, 377'
      },
      {
        id: '5',
        parkingAddress: 'Parking gratis en C. del General Palanca, 1'
      },
    ];
    var clinic = CLINICS.filter((clinic) => clinic.id == clinicId);
    var address = "";
    if(clinic) address = clinic[0].parkingAddress;
    return address;
  }
}
