import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnalyticsData } from '../models/analyticsdata';
import { AnalyticsService } from '../services/analytics.service';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private service: AnalyticsService) { }

  ngOnInit() {
    this.route.parent?.queryParams.subscribe(params => {
      var data: AnalyticsData = {
        utm_term: params['utm_term'],
        utm_campaign: params['utm_campaign'],
        utm_source: params['utm_source'],
        utm_medium: params['utm_medium'],
        utm_content: params['utm_content'],
        utm_adgroup: params['utm_adgroup'],
        gclid: params['gclid'],
        adposition: params['adposition'],
        device: params['device'],
        network: params['network'],
        loc_physical_ms: params['loc_physical_ms'],
      }
      this.service.save(data);
    });
  }
}
