/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { getPerformance } from "firebase/performance";
import { AppModule } from './app/app.module';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyCmvhOHjvG1dToQs7_-VVUcp52Bs70fwiU",
  authDomain: "holaglowdev.firebaseapp.com",
  projectId: "holaglowdev",
  storageBucket: "holaglowdev.appspot.com",
  messagingSenderId: "750574437741",
  appId: "1:750574437741:web:e6ccbb2080586be85d0664",
  measurementId: "G-XM4SHT1RV8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const perf = getPerformance(app);

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
