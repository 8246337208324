import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AnalyticsComponent } from './analytics/analytics.component';
import { CheckinComponent } from './checkin/checkin.component';
import { ContactformComponent } from './contactform/contactform.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ReagendaComponent } from './reagenda/reagenda.component';
import { SchedulerComponent } from './scheduler/scheduler.component';
import { FinancingComponent } from './financing/financing.component';

const routes: Routes = [
  { path: 'schedule', component: SchedulerComponent },
  { path: 'contactform', component: ContactformComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'analytics', component: AnalyticsComponent },
  { path: 'checkin', component: CheckinComponent },
  { path: 'reagenda', component: ReagendaComponent },
  { path: 'financing', component: FinancingComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
