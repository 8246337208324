import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'holaglowui';

  mode: boolean = false;
  backgroundColor: string = "";
  constructor(
    private route: ActivatedRoute
    ) { 
    }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      
      this.backgroundColor = params['backgroundcolor'];

      this.mode = params['mode'] == "dashboard" || 
      window.location.href.indexOf("dashboard") > 0 ||
      window.location.href.indexOf("checkin") > 0;
    });
  }
  getStyle(){
    var res = "padding-top: 10px;";
    if(this.backgroundColor){
      res += "background-color: #" + this.backgroundColor + "; ";
    }
    return res;
  }
}
