import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Treatment } from '../models/treatments';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RedirectService {
  treatments: Array<Treatment> = [];
  constructor(private cookieService: CookieService, private router: Router) {
    this.treatments = environment.treatments;
  }

  public redirectToContactForm() {
    this.router.navigate(['/contactform']);
  }

  public redictToAgenda(
    page: string,
    treatmentText: string,
    box: string,
    mode: boolean,
    checkin: boolean,
    migration: boolean,
    redirection: string
  ) {
    var treatmentText = '';
    var treatmentSkipCheckout = null;
    this.treatments.forEach((x) => {
      x.treatments.forEach((y) => {
        if (y.id.toString() == page) {
          treatmentText = y.text;
          treatmentSkipCheckout = y.skipCheckout;
        }
      });
    });

    var url = 'https://holaglow.com/checkout?treatmentText=' + treatmentText;
    if (box == '5' || treatmentSkipCheckout)
      url = 'https://holaglow.com/agenda?treatmentText=' + treatmentText;

    if (redirection && redirection !== '')
      url = `http://holaglow.com/${redirection}`;
    try {
      if (mode) {
        if (checkin) {
          this.router.navigate(['/checkin']);
        } else {
          this.router.navigate(['/dashboard']);
        }
      } else if (
        window.parent.location.href.indexOf('localhost') > 0 ||
        window.parent.location.href.indexOf('.web.app') > 0
      ) {
        this.router.navigate(['/schedule']);
      } else if (!migration) {
        window.parent.postMessage(
          '(event)Lead|' + treatmentText,
          'https://www.holaglow.com'
        );
        if (redirection && redirection !== '') {
          window.parent.postMessage(
            '(googleevent)lead_multistep|',
            'https://www.holaglow.com'
          );
        }
        window.parent.postMessage(url, 'https://www.holaglow.com');
      } else {
        window.parent.postMessage(
          '(event)Lead|' + treatmentText,
          'https://www.holaglow.com'
        );
        url = 'https://holaglow.com/thank-you-welcome/';
        window.parent.postMessage(url, 'https://www.holaglow.com');
      }
    } catch (e) {
      if (!migration) {
        window.parent.postMessage(url, 'https://www.holaglow.com');
      } else {
        url = 'https://holaglow.com/thank-you-welcome/';
        window.parent.postMessage(url, 'https://www.holaglow.com');
      }
    }
  }

  public redirectToThankYou(
    startTime: Date,
    dashboard: boolean,
    clinicId: string
  ) {
    if (!dashboard) {
      const service = this.cookieService.get('treatment');

      this.cookieService.set('previousTreatment', '', {
        expires: 30,
        sameSite: 'None',
        secure: true,
      });
      this.cookieService.set('treatment', '', {
        expires: 30,
        sameSite: 'None',
        secure: true,
      });
      this.cookieService.set('clinicToken', '', {
        expires: 30,
        sameSite: 'None',
        secure: true,
      });
      this.cookieService.set('treatmentText', '', {
        expires: 30,
        sameSite: 'None',
        secure: true,
      });
      this.cookieService.set('box', '', {
        expires: 30,
        sameSite: 'None',
        secure: true,
      });
      this.cookieService.set('referral', '', {
        expires: 30,
        sameSite: 'None',
        secure: true,
      });
      this.cookieService.set('externalReference', '', {
        expires: 30,
        sameSite: 'None',
        secure: true,
      });

      const clinicUrl: any = {
        '1': 'madrid',
        '4': 'barcelona',
        '5': 'valencia',
      };

      const BASE_URL = `https://www.holaglow.com/thank-you/${clinicUrl[clinicId]}`;

      const appointmentDate = new Date(startTime);
      const appointmentDateISO = new Date(
        appointmentDate.getTime() - appointmentDate.getTimezoneOffset() * 60000
      );
      const appointmentEndTime = new Date(appointmentDateISO).setMinutes(
        appointmentDateISO.getMinutes() + Number(service) === 902 ? 15 : 60
      );

      // yyyy-mm-dd Date format
      const urlDate = new Date(appointmentDateISO).toISOString().split('T')[0];
      // hh:mm Date format
      const urlStartTime = new Date(appointmentDateISO)
        .toISOString()
        .split('T')[1]
        .slice(0, 5);
      const urlEndTime = new Date(appointmentEndTime)
        .toISOString()
        .split('T')[1]
        .slice(0, 5);

      var url =
        BASE_URL +
        '?date=' +
        urlDate +
        '&startTime=' +
        urlStartTime +
        '&endTime=' +
        urlEndTime;

      try {
        if (
          window.parent.location.href.indexOf('localhost') > 0 ||
          window.parent.location.href.indexOf('.web.app') > 0
        ) {
          window.parent.location.href = url;
        } else {
          window.parent.postMessage(url, 'https://www.holaglow.com');
        }
      } catch (e) {
        window.parent.postMessage(url, 'https://www.holaglow.com');
      }
    } else {
      this.router.navigate(['/dashboard']);
    }
  }
}
