<div [ngClass]="{ dashboard: mode == true }">
  <div *ngIf="mode"
    class="justify-content-md-center text-center"
    style="padding: 5%; color: black"
  >
    <div class="row" *ngIf="mode">
      <div class="row">
        <div class="col">
          <h1 style="margin-bottom: 16px">¡Hola! Bienvenid@ a Holaglow</h1>
        </div>
      </div>
      <div class="col-8">
        <input
          type="name"
          class="form-control"
          [(ngModel)]="searchValue"
          #ctrl="ngModel"
          placeholder="Introduce tu teléfono, email o DNI"
          required
        />
      </div>
      <div class="col-4">
        <button
          class="btn btn-hg-primary fullWidth primaryButton"
          (click)="search()"
          id="button-addon2"
        >
          <div *ngIf="!loading2" style="margin-bottom: 0px">Buscar</div>
          <span
            *ngIf="loading2"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        </button>
      </div>
    </div>
    <div
      *ngIf="notFound"
      class="container justify-content-md-center container text-center marginTop"
    >
      <div class="align-items-center">
        <h1>¡Vaya! ¿Todavía no te conocemos?</h1>
      </div>
    </div>
  </div>
  <div
    *ngIf="!mode || (mode && notFound)"
    class="fc-wrapper"
    [ngClass]="{
      dashboard: mode == true || backgroundColor == 'b127d3'
    }"
  >
    <form [formGroup]="formData" (ngSubmit)="onSubmit(formData)" novalidate>
      <h3 *ngIf="treatmentText === 'Multistep'" class="subtitle">
        Rellena el formulario para descubrir tu tratamiento ideal
      </h3>

      <div class="name-wrapper">
        <div class="input-wrapper">
          <label>Nombre</label>
          <input
            type="name"
            placeholder="Nombre"
            class="form-control input-text"
            name="name"
            aria-describedby="namelHelp"
            formControlName="name"
            required
            [ngClass]="{
              'is-invalid':
                (formData.controls['name'].invalid &&
                  (formData.controls['name'].dirty ||
                    (formData.controls['name'].touched &&
                      formData.controls['name'].value.length > 0))) ||
                (formData.controls['name'].invalid && !disableMinCharValidation)
            }"
          />
          <div
            class="error-message"
            *ngIf="
              (formData.controls['name'].invalid &&
                (formData.controls['name'].dirty ||
                  (formData.controls['name'].touched &&
                    formData.controls['name'].value.length > 0))) ||
              (formData.controls['name'].invalid && !disableMinCharValidation)
            "
          >
            El nombre es obligatorio.
          </div>
        </div>

        <div class="input-wrapper">
          <label>Apellidos</label>
          <input
            type="name"
            placeholder="Apellidos"
            class="form-control input-text"
            name="surname"
            aria-describedby="surnameHelp"
            formControlName="surname"
            [ngClass]="{
              'is-invalid':
                (formData.controls['surname'].invalid &&
                  (formData.controls['surname'].dirty ||
                    (formData.controls['surname'].touched &&
                      formData.controls['surname'].value.length > 0))) ||
                (formData.controls['surname'].invalid &&
                  !disableMinCharValidation)
            }"
          />
          <div
            class="error-message"
            *ngIf="
              (formData.controls['surname'].invalid &&
                (formData.controls['surname'].dirty ||
                  (formData.controls['surname'].touched &&
                    formData.controls['surname'].value.length > 0))) ||
              (formData.controls['surname'].invalid &&
                !disableMinCharValidation)
            "
          >
            Los apellidos son obligatorios.
          </div>
        </div>
      </div>

      <div class="input-wrapper">
        <label>Correo electrónico</label>
        <input
          type="email"
          placeholder="Correo electrónico"
          class="form-control input-text"
          name="email"
          aria-describedby="emailHelp"
          formControlName="email"
          [ngClass]="{
            'is-invalid':
              formData.controls['email'].invalid &&
              (formData.controls['email'].dirty ||
                formData.controls['email'].touched)
          }"
        />
        <div
          class="error-message"
          *ngIf="
            formData.controls['email'].invalid &&
            (formData.controls['email'].dirty ||
              formData.controls['email'].touched)
          "
        >
          Correo electrónico incorrecto o vacío
        </div>
      </div>

      <div class="input-wrapper">
        <label>Número de teléfono</label>
        <ngx-intl-tel-input
          [cssClass]="'custom custom-test'"
          [preferredCountries]="[CountryISO.Spain]"
          [enableAutoCountrySelect]="false"
          [enablePlaceholder]="true"
          customPlaceholder="Número de teléfono"
          [searchCountryFlag]="true"
          [searchCountryField]="[
            SearchCountryField.Iso2,
            SearchCountryField.Name
          ]"
          [selectFirstCountry]="false"
          [selectedCountryISO]="CountryISO.Spain"
          [maxLength]="16"
          [phoneValidation]="true"
          [inputId]="'input-id'"
          name="phone"
          formControlName="phone"
        ></ngx-intl-tel-input>
        <div
          class="error-message"
          *ngIf="
            formData.controls['phone'].invalid &&
            formData.controls['phone'].touched
          "
        >
          Número de teléfono incorrecto o vacío
        </div>
      </div>

      <div class="input-wrapper checkbox">
        <div class="form-check noMarginBottom checkbox-wrapper">
          <input
            class="form-check-input checkbox"
            type="checkbox"
            value=""
            id="terms"
            formControlName="terms"
            [ngClass]="{
              'is-invalid':
                formData.controls['terms'].invalid &&
                (formData.controls['terms'].dirty ||
                  formData.controls['terms'].touched)
            }"
            required
          />
          <label class="form-check-label" for="terms">
            Acepto términos y condiciones
            y política de privacidad
          </label>
        </div>
        <div
          class="error-message"
          *ngIf="
            formData.controls['terms'].invalid &&
            (formData.controls['terms'].dirty ||
              formData.controls['terms'].touched)
          "
        >
          Debes aceptar los términos y condiciones
        </div>
      </div>
      <div class="row">
        <div class="form-check checkbox-wrapper">
          <input
            class="form-check-input checkbox"
            type="checkbox"
            value=""
            id="coms"
            formControlName="coms"
          />
          <label class="form-check-label" for="coms">
            Quiero que me informéis de vuestras ofertas y promociones
          </label>
        </div>
      </div>

      <button
        class="btn btn-hg-primary primaryButton"
        type="submit"
        id="button-addon2"
        [ngClass]="{ isdisabled: !formData.valid }"
      >
        <div *ngIf="!loading && !migration" style="margin-bottom: 0px">
          Continuar
        </div>
        <div *ngIf="!loading && migration" style="margin-bottom: 0px">
          Continuar
        </div>
        <span
          *ngIf="loading"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      </button>
    </form>
  </div>
</div>
