import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import {
  AnalyticsMetrics,
  SaveContactRequest,
} from '../models/requests/savecontactrequest';
import { Treatment } from '../models/treatments';
import { ContactsService } from '../services/contacts.service';
import { RedirectService } from '../services/redirect.service';
import { SchedulesService } from '../services/schedules.service';
import { Appointment } from '../models/appointment';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input-gg';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contactform',
  templateUrl: './contactform.component.html',
  styleUrls: ['./contactform.component.scss'],
})
export class ContactformComponent implements OnInit {
  formData!: FormGroup;
  submitted: boolean = false;
  page: string = '';
  box: string = '';
  loading: boolean = false;
  loading2: boolean = false;
  backgroundColor: string = '';
  mode: boolean = false;
  searchValue: string = '';
  notFound: boolean = false;
  migration: boolean = false;
  checkin: boolean = false;
  referral: string = '';
  treatments: Array<Treatment> = [];
  treatmentText: string = '';
  externalReference: string = '';
  redirection: string = '';
  disableMinCharValidation: boolean = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  analytics = getAnalytics();
  constructor(
    private builder: FormBuilder,
    private service: ContactsService,
    private router: Router,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private redirectService: RedirectService,
    private scheduleService: SchedulesService,
    private titleService: Title
  ) {
    logEvent(this.analytics, 'contact_form_loaded');
    this.treatments = environment.treatments;
    this.titleService.setTitle('HolaGlow - Formulario Contacto');
  }

  ngOnInit() {
    this.formData = this.builder.group({
      phone: new FormControl('', [Validators.required]),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(
          "[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)"
        ),
      ]),
      name: new FormControl('', [Validators.required]),
      surname: new FormControl('', [Validators.required]),
      second_surname: new FormControl('', []),
      terms: new FormControl('', [Validators.required]),
      coms: new FormControl('', []),
    });
    this.initializeParams();
    setTimeout(() => {
      this.initializeParams();
    }, 5000);
  }

  private initializeParams() {
    this.route.queryParams.subscribe((params) => {
      var device = params['device'];
      var loc_physical_ms = params['loc_physical_ms'];
      var utm_adgroup = params['utm_adgroup'];
      var utm_campaign = params['utm_campaign'];
      var utm_medium = params['utm_medium'];
      var utm_source = params['utm_source'];
      var utm_term = params['utm_term'];
      var utm_content = params['utm_content'];
      if (device)
        this.cookieService.set('device', device, {
          expires: 30,
          sameSite: 'None',
          secure: true,
        });
      if (loc_physical_ms)
        this.cookieService.set('loc_physical_ms', loc_physical_ms, {
          expires: 30,
          sameSite: 'None',
          secure: true,
        });
      if (utm_adgroup)
        this.cookieService.set('utm_adgroup', utm_adgroup, {
          expires: 30,
          sameSite: 'None',
          secure: true,
        });
      if (utm_campaign)
        this.cookieService.set('utm_campaign', utm_campaign, {
          expires: 30,
          sameSite: 'None',
          secure: true,
        });
      if (utm_medium)
        this.cookieService.set('utm_medium', utm_medium, {
          expires: 30,
          sameSite: 'None',
          secure: true,
        });
      if (utm_source)
        this.cookieService.set('utm_source', utm_source, {
          expires: 30,
          sameSite: 'None',
          secure: true,
        });
      if (utm_term)
        this.cookieService.set('utm_term', utm_term, {
          expires: 30,
          sameSite: 'None',
          secure: true,
        });
      if (utm_content)
        this.cookieService.set('utm_content', utm_content, {
          expires: 30,
          sameSite: 'None',
          secure: true,
        });
      this.backgroundColor = params['backgroundcolor'];
      this.page = params['page'];
      this.box = params['box'];
      this.mode = params['mode'] == 'dashboard' || params['mode'] == 'checkin';
      this.checkin = params['mode'] == 'checkin';
      if (this.mode) {
        this.page = '0';
        this.box = '0';
      }
      this.migration = params['migration'] == 'true';
      this.treatmentText = params['treatmentText'];
      this.referral = params['referral'] ?? '';
      this.externalReference = params['externalReference'] ?? '';
      if (!this.page) {
        this.page = this.cookieService.get('treatment');
      }
      if (!this.box) {
        this.box = this.cookieService.get('box');
      }
      this.redirection = params['redirection'];
    });
  }

  onSubmit(formData: FormGroup) {
    logEvent(this.analytics, 'contact_form_submitted');
    this.submitted = true;
    if (formData.valid && !this.loading) {
      this.loading = true;
      var email = formData.controls['email'].value;
      var name = formData.controls['name'].value;
      var phone = formData.controls['phone'].value['nationalNumber'].replace(
        / /g,
        ''
      );
      var phonePrefix = formData.controls['phone'].value['dialCode'];
      var page = this.page;
      var box = this.box;
      if (this.mode) {
        this.externalReference = '14';
      } else if (this.checkin) {
        this.externalReference = '15';
      }
      var deviceStr = this.cookieService.get('device') ?? '';
      var device = 0;
      switch (deviceStr) {
        case 'm':
          device = 3;
          break;
        case 't':
          device = 2;
          break;
        case 'd':
          device = 1;
          break;
        default:
          device = 0;
          break;
      }
      var utmCampaign = this.cookieService.get('utm_campaign') ?? '';
      var metrics: AnalyticsMetrics = {
        device: device,
        locPhysicalMs: this.cookieService.get('loc_physical_ms') ?? '',
        utmAdgroup: this.cookieService.get('utm_adgroup') ?? '',
        utmCampaign: utmCampaign,
        utmContent: this.cookieService.get('utm_content') ?? '',
        utmMedium: this.cookieService.get('utm_medium') ?? '',
        utmSource: this.cookieService.get('utm_source') ?? '',
        utmTerm: this.cookieService.get('utm_term') ?? '',
      };
      var price = 0;
      this.treatments.forEach((x) => {
        x.treatments.forEach((y) => {
          if (y.id.toString() == page) {
            price = y.price;
          }
        });
      });
      if(this.treatmentText.toLowerCase().indexOf("landing") >= 0) this.externalReference = "Landing";
      var request: SaveContactRequest = {
        email: email,
        phonePrefix: phonePrefix,
        phone: phone,
        name: formData.controls['name'].value,
        surname: formData.controls['surname'].value,
        termsAndConditionsAccepted: formData.controls['terms'].value,
        receiveCommunications:
          formData.controls['coms'].value == ''
            ? false
            : formData.controls['coms'].value,
        page: page,
        externalReference: this.externalReference ?? '',
        analyticsMetrics: metrics,
        interestedTreatment: this.treatmentText,
        treatmentPrice: price,
      };
      this.service.save(request).subscribe(
        (x) => {
          logEvent(this.analytics, 'contact_form_finished');
          var appoinmentsToSend = this.cookieService.get('appointmentsToSend');
          var clinicId = this.cookieService.get('clinicId') ?? '';
          this.cookieService.deleteAll();
          this.cookieService.set('clinicToken', x.clinicToken, {
            expires: 30,
            sameSite: 'None',
            secure: true,
          });
          this.cookieService.set('treatment', page, {
            expires: 30,
            sameSite: 'None',
            secure: true,
          });
          this.cookieService.set('box', box, {
            expires: 30,
            sameSite: 'None',
            secure: true,
          });
          this.cookieService.set('utm_campaign', utmCampaign, {
            expires: 30,
            sameSite: 'None',
            secure: true,
          });
          this.cookieService.set('email', email, {
            expires: 30,
            sameSite: 'None',
            secure: true,
          });
          this.cookieService.set('phone', phone, {
            expires: 30,
            sameSite: 'None',
            secure: true,
          });
          this.cookieService.set('phonePrefix', phonePrefix, {
            expires: 30,
            sameSite: 'None',
            secure: true,
          });
          this.cookieService.set('name', name, {
            expires: 30,
            sameSite: 'None',
            secure: true,
          });
          this.cookieService.set('treatmentText', this.treatmentText, {
            expires: 30,
            sameSite: 'None',
            secure: true,
          });
          this.cookieService.set('referral', this.referral, {
            expires: 30,
            sameSite: 'None',
            secure: true,
          });
          this.cookieService.set('externalReference', this.externalReference, {
            expires: 30,
            sameSite: 'None',
            secure: true,
          });
          if (appoinmentsToSend) {
            var appointments = JSON.parse(appoinmentsToSend) as Appointment[];
            appointments.forEach((app) => {
              app.clientId = x.clinicToken;
            });
            var date = appointments[0].startTime;
            logEvent(this.analytics, 'save_schedules_on_contactform');
            this.scheduleService.scheduleBulk(appointments).subscribe((x) => {
              logEvent(this.analytics, 'redirect_to_thankyou');
              this.redirectService.redirectToThankYou(
                new Date(date),
                false,
                clinicId
              );
            });
          } else {
            logEvent(this.analytics, 'redirect_to_agenda');
            this.redirectService.redictToAgenda(
              this.page,
              this.treatmentText,
              this.box,
              this.mode,
              this.checkin,
              this.migration,
              this.redirection
            );
          }
        },
        (err) => {
          this.loading = false;
        }
      );
    } else {
      this.disableMinCharValidation = false;
      this.validateAllFormFields(formData);
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  search() {
    this.loading2 = true;
    this.notFound = false;

    if (this.mode) {
      this.externalReference = '14';
    } else if (this.checkin) {
      this.externalReference = '15';
    }
    this.service.search(this.searchValue).subscribe(
      (x) => {
        if (x != undefined) {
          this.notFound = false;
          this.cookieService.set('treatmentText', this.treatmentText, {
            expires: 30,
            sameSite: 'None',
            secure: true,
          });
          this.cookieService.set('referral', this.referral, {
            expires: 30,
            sameSite: 'None',
            secure: true,
          });
          this.cookieService.set('clinicToken', x.clinicToken, {
            expires: 30,
            sameSite: 'None',
            secure: true,
          });
          this.cookieService.set('treatment', this.page, {
            expires: 30,
            sameSite: 'None',
            secure: true,
          });
          this.cookieService.set('email', x.email, {
            expires: 30,
            sameSite: 'None',
            secure: true,
          });
          this.cookieService.set('name', x.name, {
            expires: 30,
            sameSite: 'None',
            secure: true,
          });
          this.cookieService.set('phone', x.phone, {
            expires: 30,
            sameSite: 'None',
            secure: true,
          });
          this.cookieService.set('externalReference', this.externalReference, {
            expires: 30,
            sameSite: 'None',
            secure: true,
          });
          if (this.mode && this.checkin) {
            this.router.navigate(['/checkin']);
          } else {
            this.router.navigate(['/dashboard']);
          }
        } else {
          this.formData.controls['email'].setValue('');
          this.formData.controls['phone'].setValue('');
          this.formData.controls['name'].setValue('');
          this.formData.controls['surname'].setValue('');
          this.formData.controls['second_surname'].setValue('');
          this.formData.controls['terms'].setValue(true);
          this.formData.controls['coms'].setValue(true);
          this.notFound = true;
        }
        this.loading2 = false;
      },
      (err) => {
        this.notFound = true;
        this.loading2 = false;
      }
    );
  }
}
