export const environment = {
  contactsApi: 'https://holaglowcontactsapi.azurewebsites.net',
  scheduleApi: 'https://holaglowscheduleapi.azurewebsites.net',
  financeApi: 'https://holaglowsfinanceapi.azurewebsites.net',
  analyticsApi: 'https://holaglowsanalyticsapi.azurewebsites.net',
  treatments: [
    {
      family: 'Asesoramiento',
      treatments: [
        {
          text: 'Primera Visita',
          id: 903,
          box: 6,
          price: 0,
          skipCheckout: false,
          availableBcn: true,
        },
        {
          text: 'Probador Virtual',
          id: 902,
          box: 5,
          price: 0,
          skipCheckout: true,
          availableBcn: true,
        },
        {
          text: 'Visita Seguimiento',
          id: 904,
          box: 6,
          price: 0,
          skipCheckout: false,
          availableBcn: true,
        },
      ],
    },
    {
      family: 'Aumento Lábios',
      treatments: [
        {
          text: 'Aumento de labios',
          id: 674,
          box: 6,
          price: 289,
          skipCheckout: false,
          availableBcn: true,
        },
      ],
    },
    {
      family: 'Capilar',
      treatments: [
        {
          text: 'Rejuvenecimiento capilar',
          id: 865,
          box: 6,
          price: 120,
          skipCheckout: false,
          availableBcn: true,
        },
      ],
    },
    {
      family: 'Facial',
      treatments: [
        {
          text: 'Arrugas entrecejo y patas de gallo',
          id: 850,
          box: 6,
          price: 199,
          skipCheckout: false,
          availableBcn: true,
        },
        {
          text: 'Arrugas expresión: Frente, entrecejo y patas de gallo',
          id: 851,
          box: 6,
          price: 369,
          skipCheckout: false,
          availableBcn: true,
        },
        {
          text: 'Código de barras',
          id: 849,
          box: 6,
          price: 299,
          skipCheckout: false,
          availableBcn: true,
        },
        {
          text: 'Lifting facial cara',
          id: 858,
          box: 6,
          price: 499,
          skipCheckout: false,
          availableBcn: true,
        },
        {
          text: 'Lifting facial cuello',
          id: 859,
          box: 6,
          price: 399,
          skipCheckout: false,
          availableBcn: true,
        },
        {
          text: 'Prevención arrugas - Baby botox',
          id: 856,
          box: 6,
          price: 199,
          skipCheckout: false,
          availableBcn: true,
        },
        {
          text: 'Proyección de mandíbula',
          id: 853,
          box: 6,
          price: 299,
          skipCheckout: false,
          availableBcn: true,
        },
        {
          text: 'Proyección de mentón',
          id: 852,
          box: 6,
          price: 299,
          skipCheckout: false,
          availableBcn: true,
        },
        {
          text: 'Proyección de Pómulos',
          id: 847,
          box: 6,
          price: 299,
          skipCheckout: false,
          availableBcn: true,
        },
        {
          text: 'Relleno de ojeras',
          id: 854,
          box: 6,
          price: 349,
          skipCheckout: false,
          availableBcn: true,
        },
        {
          text: 'Rinomodelación',
          id: 855,
          box: 6,
          price: 389,
          skipCheckout: false,
          availableBcn: true,
        },
        {
          text: 'Sonrisa Gingival',
          id: 857,
          box: 6,
          price: 299,
          skipCheckout: false,
          availableBcn: true,
        },
        {
          text: 'Surco Nasogeniano',
          id: 848,
          box: 6,
          price: 299,
          skipCheckout: false,
          availableBcn: true,
        },
        {
          text: 'Lifting Liquido',
          id: 2015,
          box: 6,
          price: 399,
          skipCheckout: false,
          availableBcn: true,
        },
        {
          text: 'Microneedling',
          id: 2115,
          box: 8,
          price: 89,
          skipCheckout: true,
          availableBcn: true,
        },

        {
          text: 'Relleno lineas marioneta',
          id: 2112,
          box: 6,
          price: 299,
          skipCheckout: false,
          availableBcn: true,
        },
        {
          text: 'Volumen y perfilado de cejas',
          id: 2113,
          box: 6,
          price: 299,
          skipCheckout: false,
          availableBcn: true,
        },
        {
          text: 'Rejuvenecimiento dinámico',
          id: 2114,
          box: 6,
          price: 799,
          skipCheckout: false,
          availableBcn: true,
        },
      ],
    },
    {
      family: 'HydraFacial',
      treatments: [
        {
          text: 'Hydrafacial: Deluxe',
          id: 2151,
          box: 11,
          price: 199,
          skipCheckout: true,
          availableBcn: true,
        },
        {
          text: 'Hydrafacial: Express',
          id: 2147,
          box: 11,
          price: 150,
          skipCheckout: true,
          availableBcn: true,
        },
        {
          text: 'Hydrafacial: Platinum',
          id: 2155,
          box: 11,
          price: 240,
          skipCheckout: true,
          availableBcn: true,
        },
        {
          text: 'Radiofrecuencia',
          id: 2159,
          box: 8,
          price: 79,
          skipCheckout: true,
          availableBcn: false,
        },
      ],
    },
    {
      family: 'Médico',
      treatments: [
        {
          text: 'Bruxismo',
          id: 867,
          box: 6,
          price: 349,
          skipCheckout: false,
          availableBcn: true,
        },
        {
          text: 'Sudoración excesiva',
          id: 866,
          box: 6,
          price: 599,
          skipCheckout: false,
          availableBcn: true,
        },
      ],
    },
    {
      family: 'Ácido Hialurónico',
      treatments: [
        {
          text: 'Hidratación profunda',
          id: 2109,
          box: 6,
          price: 299,
          skipCheckout: true,
          availableBcn: true,
        },
      ],
    },
    {
      family: 'Pack',
      treatments: [
        {
          text: 'Full Face (3 AH + 1 Botox )',
          id: 974,
          box: 6,
          price: 1499,
          skipCheckout: false,
          availableBcn: true,
        },
        {
          text: 'Glow Pack  (3 MProf + 0,5 Botox + 1 FMag)',
          id: 975,
          box: 6,
          price: 699,
          skipCheckout: false,
          availableBcn: true,
        },
        {
          text: 'Armonización Facial (3 AH)',
          id: 973,
          box: 6,
          price: 899,
          skipCheckout: false,
          availableBcn: true,
        },
        {
          text: 'Pack Prevención (1 AH + 0,5 Botox)',
          id: 970,
          box: 6,
          price: 549,
          skipCheckout: false,
          availableBcn: true,
        },
        {
          text: 'Pack Wellaging Esencial (1 AH + 1 Botox)',
          id: 972,
          box: 6,
          price: 699,
          skipCheckout: false,
          availableBcn: true,
        },
        {
          text: 'Pack Wellaging Plus (2 AH + 1 Botox)',
          id: 971,
          box: 6,
          price: 999,
          skipCheckout: false,
          availableBcn: true,
        },
      ],
    },
    {
      family: 'Piel',
      treatments: [
        {
          text: 'Peeling Químico Básico',
          id: 862,
          box: 6,
          price: 79,
          skipCheckout: true,
          availableBcn: true,
        },
        {
          text: 'Revitalización facial: Mesoterapia',
          id: 860,
          box: 6,
          price: 169,
          skipCheckout: true,
          availableBcn: true,
        },
      ],
    },
    {
      family: 'Upgrade Facial',
      treatments: [
        {
          text: 'Arrugas entrecejo y patas de gallo + Vitaminas',
          id: 910,
          box: 6,
          price: 4269,
          skipCheckout: false,
          availableBcn: true,
        },
        {
          text: 'Arrugas expresión: Frente, entrecejo y patas de gallo + Vitaminas',
          id: 911,
          box: 6,
          price: 439,
          skipCheckout: false,
          availableBcn: true,
        },
        {
          text: 'Código de barras + Vitaminas',
          id: 909,
          box: 6,
          price: 369,
          skipCheckout: false,
          availableBcn: true,
        },
        {
          text: 'Lifting facial cara + Vitaminas',
          id: 918,
          box: 6,
          price: 569,
          skipCheckout: false,
          availableBcn: true,
        },
        {
          text: 'Lifting facial cuello + Vitaminas',
          id: 919,
          box: 6,
          price: 469,
          skipCheckout: false,
          availableBcn: true,
        },
        {
          text: 'Prevención arrugas - Baby botox + Vitaminas',
          id: 916,
          box: 6,
          price: 269,
          skipCheckout: false,
          availableBcn: true,
        },
        {
          text: 'Proyección de mandíbula + Vitaminas',
          id: 913,
          box: 6,
          price: 369,
          skipCheckout: false,
          availableBcn: true,
        },
        {
          text: 'Proyección de mentón + Vitaminas',
          id: 912,
          box: 6,
          price: 369,
          skipCheckout: false,
          availableBcn: true,
        },
        {
          text: 'Proyección de Pómulos + Vitaminas',
          id: 907,
          box: 6,
          price: 369,
          skipCheckout: false,
          availableBcn: true,
        },
        {
          text: 'Relleno de ojeras + Vitaminas',
          id: 914,
          box: 6,
          price: 419,
          skipCheckout: false,
          availableBcn: true,
        },
        {
          text: 'Rinomodelación + Vitaminas',
          id: 915,
          box: 6,
          price: 459,
          skipCheckout: false,
          availableBcn: true,
        },
        {
          text: 'Sonrisa Gingival + Vitaminas',
          id: 917,
          box: 6,
          price: 369,
          skipCheckout: false,
          availableBcn: true,
        },
        {
          text: 'Surco Nasogeniano + Vitaminas',
          id: 908,
          box: 6,
          price: 369,
          skipCheckout: false,
          availableBcn: true,
        },
      ],
    },
    {
      family: 'Upgrade Labios',
      treatments: [
        {
          text: 'Aumento de labios + Vitaminas',
          id: 906,
          box: 6,
          price: 359,
          skipCheckout: false,
          availableBcn: true,
        },
      ],
    },
  ],
  closedDays: [
    '2023-3-20',
    '2023-4-6',
    '2023-4-7',
    '2023-4-8',
    '2023-5-1',
    '2023-5-2',
    '2023-5-15',
    '2023-7-3',
    '2023-8-15',
    '2023-10-12',
    '2023-10-9',
    '2023-12-6',
    '2023-12-8',
    '2023-12-25',
  ],
};
