import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Appointment } from '../models/appointment';
import { ContactsService } from '../services/contacts.service';
import { SchedulesService } from '../services/schedules.service';

@Component({
  selector: 'app-checkin',
  templateUrl: './checkin.component.html',
  styleUrls: ['./checkin.component.scss']
})
export class CheckinComponent {

  loading: boolean = false;
  loading2: boolean = false;
  notFound: boolean = false;
  notFound2: boolean = false;
  confirmed: boolean = false;
  searchValue: string = "";
  appointment: Appointment | undefined = undefined;
  name: string = "";
  constructor(private service: ContactsService,
    private schedules: SchedulesService,
    private cookieService:CookieService,
    private router: Router){
    
      this.name = this.cookieService.get("name");
  }

  confirm(){
    this.loading2 = true;
    this.schedules.confirm(this.appointment!).subscribe(x => {
      this.confirmed = true;
    });
  }

  
  openHolaGlowAgenda() {
    open("https://agenda2.holaglow.com/schedule?mode=dashboard");
  }

  openFlowww(){
    open("https://eu013.flowww.net/240954/flowww.asp");
  }

  
  openFlowwwApp(){
    open("https://eu013.flowww.net/240954/flowww.asp");
  }
  open(url: string){
    window.open(url, "_blank");
  }
  logout(){
    this.router.navigate(['/contactform'], { queryParams: { mode: 'checkin' , page: '0'}});
  }

}
