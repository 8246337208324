import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Appointment } from '../models/appointment';
import { RescheduleAppointmentRequest } from '../models/requests/rescheduleappointmentrequest';
import { environment } from './../../environments/environment';
import { Slot } from '../models/slot';
import { DayAvailability, MonthAvailabilityResponse } from '../models/dayavailability';

@Injectable({
  providedIn: 'root'
})
export class SchedulesService {
  format: string = "yyyy-MM-dd";
  constructor(private http: HttpClient,
    public datepipe: DatePipe) {  }

  retrieve(startDate: Date, endDate: Date, box: string) : Observable<Array<Appointment>>{
    return this.http.get<Array<Appointment>>(environment.scheduleApi + "/Appointment?startDate=" + 
    this.datepipe.transform(startDate,this.format)  + "&endDate=" + 
    this.datepipe.transform(endDate,this.format) + "&box=" + box);
  }
  slotsv2(date: Date, treatment: string, clinicId: string) : Observable<Array<Slot>>{
    return this.http.get<Array<Slot>>(environment.scheduleApi + "/Appointment/Slotsv2?date=" + 
    this.datepipe.transform(date,this.format)  + "&treatment=" + treatment + "&clinicId=" + clinicId);
  }

  monthAvailabilityv2(date: Date, treatment: string, clinicId: string) : Observable<MonthAvailabilityResponse>{
    return this.http.get<MonthAvailabilityResponse>(environment.scheduleApi + "/Appointment/MonthAvailabilityv2?date=" + 
    this.datepipe.transform(date,this.format)  + "&treatment=" + treatment + "&clinicId=" + clinicId);
  }
 
  slots(date: Date, treatment: string, clinicId: string) : Observable<Array<Slot>>{
    return this.http.get<Array<Slot>>(environment.scheduleApi + "/Appointment/Slots?date=" + 
    this.datepipe.transform(date,this.format)  + "&treatment=" + treatment + "&clinicId=" + clinicId);
  }

  monthAvailability(date: Date, treatment: string, clinicId: string) : Observable<MonthAvailabilityResponse>{
    return this.http.get<MonthAvailabilityResponse>(environment.scheduleApi + "/Appointment/MonthAvailability?date=" + 
    this.datepipe.transform(date,this.format)  + "&treatment=" + treatment + "&clinicId=" + clinicId);
  }
  
  next(token: string) : Observable<Array<Appointment>>{
    return this.http.get<Array<Appointment>>(environment.scheduleApi + "/Appointment/Next?token=" + token);
  }

  
  confirm(appointment: Appointment) : Observable<any>{
    return this.http.post<any>(environment.scheduleApi + "/Appointment/Confirm", appointment);
  }

  reschedule(request: RescheduleAppointmentRequest) : Observable<any>{
    return this.http.post<any>(environment.scheduleApi + "/Appointment/Reschedule", request);
  }

  cancel(appointment: Appointment) : Observable<any>{
    return this.http.delete<any>(environment.scheduleApi + "/Appointment", {body: appointment});
  }
  
  schedule(appointment: Appointment) : Observable<Appointment> {
    return this.http.post<Appointment>(environment.scheduleApi + "/Appointment", appointment);
  }
  
  scheduleBulk(appointments: Appointment[]) : Observable<Appointment[]> {
    return this.http.post<Appointment[]>(environment.scheduleApi + "/Appointment/Bulk", appointments);
  }
  
}
