import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Contact } from '../models/contact';
import { SaveContactRequest } from '../models/requests/savecontactrequest';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {
  constructor(private http: HttpClient ) {  

  }

  save(request: SaveContactRequest): Observable<Contact> {
    return this.http.post<Contact>(environment.contactsApi + "/Contact", request);
  }
  
  search(search: string): Observable<Contact> {
    return this.http.get<Contact>(environment.contactsApi + "/Contact/Search?search=" + search);
  }
}
