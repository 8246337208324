import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  name: string | null = '';
  constructor(private router: Router, private cookieService: CookieService) {
    this.name = this.cookieService.get('name');
  }
  price: string = '';
  openCrisalix() {
    open('https://pro.crisalix.com/');
  }

  openModal(content: any) {
    this.router.navigate(['/financing']);
  }
  openHolaGlow() {
    open('https://www.holaglow.com/tratamientos-holaglow');
  }

  openBudgets() {
    this.open('https://holaglow.com/precios/');
  }

  openHolaGlowAgenda() {
    this.router.navigate(['/schedule'], { queryParams: { mode: 'dashboard' } });
  }

  openFlowww() {
    open('https://eu013.flowww.net/240954/flowww.asp');
  }

  openImstant() {
    open('https://imstant.es/diagnostico-gratuito/#!');
  }

  open(url: string) {
    window.open(url, '_blank');
  }

  logout() {
    this.router.navigate(['/contactform'], {
      queryParams: { mode: 'dashboard', page: '0' },
    });
  }
}
