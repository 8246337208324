<div
  class="container schedulerContainer justify-content-md-center container text-center dashboard background"
>
  <div
    class="schedulerContainer row justify-content-md-center container text-center"
    style="color: black; padding-top: 50px"
  >
    <div *ngIf="name" class="align-items-center">
      <h1>¡Hola {{ name }}! Bienvenid@ a Holaglow</h1>
    </div>
    <div class="align-items-center">
      <h1>¿Preparad@ para tu primera vez?</h1>
    </div>
    <div style="width: 75%; margin: 0 auto">
      <div class="row">
        <div class="col-4">
          <img
            src="./../../assets/dashboard_icons/facial_scan.png"
            (click)="openImstant()"
          />
          <label style="font-size: 26px">Escaner facial</label>
        </div>
        <div class="col-4">
          <img
            src="./../../assets/dashboard_icons/3d_simulator.png"
            (click)="openCrisalix()"
          />
          <label style="font-size: 26px">Simulador 3D</label>
        </div>
        <div class="col-4">
          <img
            src="./../../assets/dashboard_icons/budgets.png"
            (click)="openBudgets()"
          />
          <label style="font-size: 26px">Presupuestos</label>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <img
            src="./../../assets/dashboard_icons/financing.png"
            (click)="openModal('')"
          />
          <label style="font-size: 26px">Financiación</label>
        </div>
        <div class="col-4">
          <img
            src="./../../assets/dashboard_icons/agenda.png"
            (click)="openHolaGlowAgenda()"
          />
          <label style="font-size: 26px">Agendar cita</label>
        </div>
        <div class="col-4">
          <img
            src="./../../assets/dashboard_icons/checkout.png"
            (click)="openFlowww()"
          />
          <label style="font-size: 26px">Checkout</label>
        </div>
      </div>
    </div>
  </div>
  <div class="floating">
    <img src="../../assets/dashboard_icons/logout.png" (click)="logout()" />
  </div>
</div>
